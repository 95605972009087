// Create browser compatible event handler.
var eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
var eventer = window[eventMethod];

var messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";

const isRedirectPortalTecon = localStorage.getItem('redirectDocumental') == 'true' ? true : false;

window.onstorage = (event) => {
    // Portal null Documental
    if (event.key === 'Portal') {
        if (event.newValue == 'Documental') {
            var data = {
                type: "refresh",
                message: 'Tecon'
            };
            window.top.postMessage(data, '*');
        }
    }
};

window.onload = (event) => {
    // create an Observer instance
    const resizeObserver = new ResizeObserver(entries =>
        sendHeight()
    );

    // start observing a DOM node
    const element = document.querySelector('#main-container');

    if (element != null)
        resizeObserver.observe(element);

    sendHeight();
};

if (isRedirectPortalTecon) {
    // Listen for a message from the iframe.
    eventer(messageEvent, function (e) {
        if (isNaN(e.data)) return;
        sendHeight();
    }, false);
}

function sendHeight() {
    if (parent.postMessage) {
        // actual page content
        var container = document.getElementById('main-container');

        if (!container) return;

        var height = container.offsetHeight;

        parent.postMessage(height, '*');
    }
}

function setToasts(msg, messageType) {
    var data = {
        type: "message",
        message: msg,
        messageType: messageType
    };
    sendHeight();
    window.top.postMessage(data, '*');
}

// Remove o erro no console.log
if (typeof module !== 'undefined' && module.exports) {
    module.exports = { setToasts };
} else if (typeof define === 'function' && define.amd) {
    define(function () {
        return { setToasts };
    });
} else {
    window.setToasts = function (msg, messageType) {
        var data = {
            type: "message",
            message: msg,
            messageType: messageType
        };
        sendHeight();
        window.top.postMessage(data, '*');
    };
}
